import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
require("swiper/swiper-bundle.css");
import Img from "gatsby-image";
import parse from "html-react-parser";

SwiperCore.use([Autoplay, EffectCoverflow]);

const query = graphql`
	query {
		strapiPageOutils {
			title
			slug
			body
			seo {
				metaDescription
				metaTitle
			}
		}
		slidePictures: allFile(
			filter: { sourceInstanceName: { eq: "tools" } }
			sort: { fields: name }
		) {
			edges {
				node {
					childImageSharp {
						fluid(srcSetBreakpoints: [488], maxWidth: 488) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
	}
`;

const Outils = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageOutils;
	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<div className="sub-container">{parse(page.body)}</div>
					<Swiper
						effect={`coverflow`}
						grabCursor={true}
						centeredSlides={true}
						slidesPerView={`auto`}
						coverflowEffect={{
							rotate: 50,
							stretch: 0,
							depth: 100,
							modifier: 1,
							slideShadows: true,
						}}
						speed={2000}
						autoplay={{ delay: 2000 }}
						className={`mt-5`}
					>
						{data.slidePictures.edges.map((image, index) => {
							let tool = {
								url: "",
								title: "",
							};
							switch (index) {
								case 0:
									tool.url = `https://www.apprendre-a-apprendre.info/`;
									tool.title = `Exercices et jeux en ligne de dictées, multiplication et langues étrangères en utilisant la pédagogie PNL`;
									break;
								case 1:
									tool.url = `https://lire.cool/`;
									tool.title = `Pour développer les stratégies de lecture chez les 8-15 ans, de façon progressive et ludique`;
									break;
								case 2:
									tool.url = `https://revisez.eu/`;
									tool.title = `Leçons et exercices utilisant la pédagogie PNL`;
									break;
							}

							return (
								<SwiperSlide key={image.node.childImageSharp.fluid.src}>
									<div className={`slide`}>
										<Img
											fluid={image.node.childImageSharp.fluid}
											alt={tool.title}
											title={tool.title}
										/>
									</div>
									<div className={`button-action`}>
										<p className={`text-center`}>{tool.title}</p>
										<a href={tool.url} target="_blank" rel="noopener">
											<button className={`btn btn--green`}>
												En savoir plus{" "}
											</button>
										</a>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			</div>
		</Layout>
	);
};

export default Outils;
